import { DocumentType } from '@frontline/common';
import { MessageDescriptor } from 'react-intl';
import messages from '../components/upload-documents.messages';

export const getMessageDefinitionForDocumentTitle = (
  type: DocumentType,
  documentTypeRequired: boolean,
): MessageDescriptor => {
  switch (type) {
    case DocumentType.BillOfSale:
      return messages.billOfSaleTitle;
    case DocumentType.AdditionalDocument:
      if (documentTypeRequired) {
        return messages.additionalDocumentsRequiredTitle;
      }

      return messages.additionalDocumentsOptionalTitle;
    case DocumentType.BankTransactions:
      return messages.bankTransactionsTitle;
    case DocumentType.SignedContract:
      return messages.signedContractTitle;
    case DocumentType.UnsignedLoanProtection:
      return messages.unsignedLoanProtectionTitle;
    case DocumentType.VoidCheque:
      return messages.voidChequeTitle;
    case DocumentType.SignedAgreement:
      return messages.signedAgreementTitle;
    case DocumentType.FinalFundingPackage:
      return messages.finalFundingPackageTitle;
    case DocumentType.VehicleOwnership:
      return messages.vehicleOwnershipTitle;
    default:
      return messages.unknownDocument;
  }
};

export const getMessageDefinitionForDocumentDescription = (
  type: DocumentType,
): MessageDescriptor => {
  switch (type) {
    case DocumentType.BillOfSale:
      return messages.billOfSaleDescription;
    case DocumentType.AdditionalDocument:
      return messages.additionalDocumentsDescription;
    case DocumentType.BankTransactions:
      return messages.bankTransactionsDescription;
    case DocumentType.SignedContract:
      return messages.signedContractDescription;
    case DocumentType.UnsignedLoanProtection:
      return messages.unsignedLoanProtectionDescription;
    case DocumentType.VoidCheque:
      return messages.voidChequeDescription;
    case DocumentType.SignedAgreement:
      return messages.signedAgreementDescription;
    case DocumentType.FinalFundingPackage:
      return messages.finalFundingPackageDescription;
    case DocumentType.VehicleOwnership:
      return messages.vehicleOwnershipDescription;
    default:
      return messages.unknownDocument;
  }
};
