import { WorkflowStepType } from '@frontline/common';
import {
  PaperCard,
  styled,
  styledTheme,
  withShowIf,
} from '@frontline/ui-library';
import { FundingDetails, ReviewStepper } from '@frontline/web-common';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { UploadDocumentsButton } from '../../../../common/UploadDocumentsButton/upload-documents-button.component';
import { getStepMessageDefinition } from '../../../../types';
import { isUploadDocumentsVisible } from '../../types/workflow.functions';
import { PostSubmissionReviewSidebarProps } from './post-submission-review-sidebar.props';

export const PostSubmissionReviewSidebar = withShowIf(
  (props: PostSubmissionReviewSidebarProps) => {
    const intl = useIntl();
    const history = useHistory();

    const getStepNameByStepType = (stepType: WorkflowStepType): string => {
      return intl.formatMessage(
        getStepMessageDefinition(stepType, props.applicationProgramType),
        {
          applicantName: props.applicantName,
          coApplicantName: props.coApplicantName,
        },
      );
    };

    return (
      <SidebarGrid container={true} spacing={2} direction="column">
        <SidebarGrid item={true} showIf={!Boolean(props.hideFundingDetails)}>
          <PaperCard
            padding="0em"
            background={styledTheme.darkBlueGrey}
            color="white">
            <FundingDetails
              estimatedPayment={props.estimatedPayment}
              isCalculating={props.estimatedPaymentIsCalculating}
              estimatedFrequency={props.estimatedFrequency}
              loanDetails={props.loanDetails}
            />
          </PaperCard>
        </SidebarGrid>
        <SidebarGrid item={true}>
          <PaperCard padding="0em">
            <ReviewStepper
              steps={props.steps}
              getStepNameByStepType={getStepNameByStepType}
              routeSelector={history.push}
            />
          </PaperCard>
        </SidebarGrid>
        <SidebarGrid item={true}>
          <UploadDocumentsButton
            xs={12}
            showIf={isUploadDocumentsVisible(props.applicantOptions)}
            uploadDocsStep={props.uploadDocumentsStep}
          />
        </SidebarGrid>
      </SidebarGrid>
    );
  },
);

const SidebarGrid = withShowIf(styled(Grid)`
  width: 100%;
`);
